export const EventStatus = {
  Scheduled: 1,
  InProgress: 2,
  Complete: 3,
  Paused: 4,
}

export const RoundStatus = {
  WaitingToStart: 1,
  InProgress: 2,
  Complete: 3,
}

export const ActivityTrans = {
  userJoined: 'joined the lobby',
  userRejoined: 'rejoined the lobby',
  userLeft: 'left the lobby',
}

export const WebsocketEvent = {
  GroupActivity: '.App\\Events\\ParticipantGroupActivityEvent',
  GroupLeaderboard: '.App\\Events\\ParticipantGroupLeaderboardUpdatedEvent',
  LobbyActivity: '.App\\Events\\LobbyActivityEvent',
  LobbyLeaderboard: '.App\\Events\\LobbyLeaderboardUpdatedEvent',
  RoundLeaderboard: '.App\\Events\\RoundLeaderboardUpdatedEvent',
  OutingEdited: '.App\\Events\\OutingEditedEvent',
  OutingDeleted: '.App\\Events\\OutingDeletedEvent',
  OutingStarted: '.App\\Events\\OutingStartedEvent',
  OutingEnded: '.App\\Events\\OutingEndedEvent',
  RoundStarted: '.App\\Events\\OutingRoundStartedEvent',
  RoundEnded: '.App\\Events\\OutingRoundEndedEvent',
  OutingResumed: '.App\\Events\\OutingResumedEvent',
  OutingPaused: '.App\\Events\\OutingPausedEvent',
  OutingTimeSkipped: '.App\\Events\\OutingTimeSkippedEvent',
  VideoCallLinkUpdated: '.App\\Events\\OutingVideoCallLinkUpdatedEvent',
  OutingRoundDurationElapsed: '.App\\Events\\OutingRoundDurationElapsedEvent',
  PokerUserWentBroke: '.App\\Events\\PokerUserWentBrokeEvent',
}

export const AppScreens = {
  Landing: 'landing_screen',
  EventLobby: 'event_lobby_screen',
  GamePlay: 'game_play_screen',
  EventFinished: 'event_finished_screen',
}

export const OrdinalSuffix = (function generateOrdinalSuffixes() {
  const suffixes = new Array(31).fill('').map((e, index) => {
    return 'th'
  })
  suffixes[0] = ''
  suffixes[1] = 'st'
  suffixes[2] = 'nd'
  suffixes[3] = 'rd'
  suffixes[21] = 'st'
  suffixes[22] = 'nd'
  suffixes[23] = 'rd'
  return suffixes
})()

export const StringRanks = (function generateOrdinalNumbers() {
  return new Array(31).fill('').map((e, index) => {
    return `${index}${OrdinalSuffix[index]}`
  })
})()

export const DefaultAvatar = '/images/default_user_avatar.png'
export const Practice = {
  TokenParam: 'practice',
  Duration: 60,
  BlinkThreshold: 20,
}

export const DurationOptions = [15, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300].map(minute => ({
  value: minute,
  label: `${minute} minutes`,
}))

export const ClientScreens = {
  LANDING_SCREEN: 'LANDING_SCREEN',
  EDIT_EVENT_SCREEN: 'EDIT_EVENT_SCREEN',
  INVITE_MEMBER_SCREEN: 'INVITE_MEMBER_SCREEN',
  USER_SETTINGS_SCREEN: 'USER_SETTINGS_SCREEN',
}

export const GameSlug = {
  MiniGolf: 'mini-golf-adventures',
  TriviaKing: 'trivia-king',
  EightBall: '8-ball-pool',
  CandyJam: 'candy-jam',
  UnblockIt: 'unblock-it',
  MillionaireQuiz: 'millionaire-quiz',
  Hangman: 'hangman',
  Pictionary: 'pictionary',
  Poker: 'poker',
  FourInARow: '4-in-a-row',
  ClashOfTrivia: 'clash-of-trivia',
}

export const GameIconAltText = {
  [GameSlug.Poker]: 'Poker with Buddies Game Icon',
  [GameSlug.MiniGolf]: 'Golf With Friends Game Icon',
  [GameSlug.TriviaKing]: 'Trivia King Game Icon',
  [GameSlug.EightBall]: '8 Ball Pool Game Icon',
  [GameSlug.CandyJam]: 'Candy Jam Game Icon',
  [GameSlug.UnblockIt]: 'Unblock It Game Icon',
  [GameSlug.MillionaireQuiz]: 'Millionaire Quiz Game Icon',
  [GameSlug.Hangman]: 'Hangman Game Icon',
  [GameSlug.Pictionary]: 'Drawn Together Game Icon',
  [GameSlug.ClashOfTrivia]: 'Clash of Trivia Game Icon',
  [GameSlug.FourInARow]: '4 in a Row Game Icon',
}

export const GameScreenshotAltText = {
  [GameSlug.Poker]: 'Poker with Buddies - Screenshot',
  [GameSlug.MiniGolf]: 'Mini Golf - Screenshot',
  [GameSlug.TriviaKing]: 'Trivia King - Screenshot',
  [GameSlug.EightBall]: '8 Ball Pool - Screenshot',
  [GameSlug.CandyJam]: 'Candy Jam - Screenshot',
  [GameSlug.UnblockIt]: 'Unblock It - Screenshot',
  [GameSlug.MillionaireQuiz]: 'Millionaire Quiz - Screenshot',
  [GameSlug.Hangman]: 'Hangman - Screenshot',
  [GameSlug.Pictionary]: 'Drawn Together - Screenshot',
  [GameSlug.ClashOfTrivia]: 'Clash of Trivia - Screenshot',
  [GameSlug.FourInARow]: '4 in a Row - Screenshot',
}

export const GameOneLinerFAQ = {
  [GameSlug.MiniGolf]:
    'Putt the ball into the hole. Use the shortest amount of putts to score maximum stars. Collect gems to increase your score!',
  [GameSlug.TriviaKing]: '',
  [GameSlug.EightBall]: 'Compete 1 on 1 and race to the 8th ball to get the max score!',
  [GameSlug.CandyJam]:
    'Tap and drag the candy to accumulate points. Match 3 or more of the same candy to score points.',
  [GameSlug.UnblockIt]: 'Drag or swipe the wooden blocks. Clear a path for the colored block.',
  [GameSlug.MillionaireQuiz]: '',
  [GameSlug.Hangman]:
    'Earn points for each word guessed correctly. We recommed using hints and completing the game the fastest!',
  [GameSlug.Pictionary]:
    'Discover your inner artist, and your colleagues hidden talents in this drawing and guessing game!',
  [GameSlug.Poker]: "Play Texas Hold 'Em poker with your teams!",
  [GameSlug.ClashOfTrivia]: 'Clash with friends via this PvP trivia game.',
  [GameSlug.FourInARow]: 'Connect four dots in a row in any direction. Can you beat your opponent?',
}

export const AppTitle = 'Team Games | Virtual Team Building Activities'

export const TeamGamesMetaDescription =
  "Every team needs a break! Grab your group for quick and fun team building activities, no other apps required! Create an Outing and we'll handle the rest, while you focus on having fun with your team!"

export const TeamGamesOGImagePath = `${
  typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''
}/images/team_games_logo.png`

export const wpTagSlugById = {
  15: 'announcement',
  16: 'release-notes',
  17: 'all-games',
  19: 'faq',
}
export const wpTagIdBySlug = []
wpTagIdBySlug['news'] = '15,16'
wpTagIdBySlug['announcement'] = '15'
wpTagIdBySlug['release-notes'] = '16'
wpTagIdBySlug['all-games'] = '17'
wpTagIdBySlug['faq'] = '19'

export const wpRoutePrefix = '/blog'

export const blogDefaultFeaturedImage = []
blogDefaultFeaturedImage['announcement'] = '/images/wpTag/announcement_featured_image.png'
blogDefaultFeaturedImage['release-notes'] = '/images/wpTag/release_notes_featured_image.png'
blogDefaultFeaturedImage['all-games'] = '/images/wpTag/all_games_featured_image.png'
blogDefaultFeaturedImage['faq'] = '/images/wpTag/FAQ_featured_image.png'
