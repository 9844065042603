import React, { createContext, useContext, useRef, useState } from 'react'
import _throttle from 'lodash/throttle'

const ViewportContext = createContext({
  isTwoColumnView: true,
  isPopoutRequired: false,
  isMobile: false,
  isTouch: false,
  isLandscape: false,
  width: 1920,
  height: 935,
})

const Breakpoint = {
  PopoutRequired: 400, // to display alerts to pop out
  TowColumnView: 992, // to determine one-column or two-column view
  Mobile: 500, // to determine mobile view
}

export const ViewportProvider = ({ children }) => {
  const [isTwoColumnView, setIsTwoColumnView] = useState(true)
  const [width, setWidth] = useState(1920)
  const [height, setHeight] = useState(935)
  const [isPopoutRequired, setIsPopoutRequired] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isLandscape, setIsLandscape] = useState(false)
  const [isTouch, setIsTouch] = useState(false)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    setIsTwoColumnView(window.innerWidth >= Breakpoint.TowColumnView)
    setIsPopoutRequired(window.innerWidth <= Breakpoint.PopoutRequired)
    setIsMobile(window.innerWidth <= Breakpoint.Mobile)
    setIsLandscape(window.matchMedia('(orientation: landscape)').matches)
    setIsTouch(window.matchMedia('(hover: none), (pointer: coarse)').matches)
  }
  const { current: handleWindowResizeThrottled } = useRef(_throttle(handleWindowResize, 250))

  React.useEffect(() => {
    handleWindowResizeThrottled()
    window.addEventListener('resize', handleWindowResizeThrottled)
    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ViewportContext.Provider
      value={{
        isTwoColumnView,
        isPopoutRequired,
        isMobile,
        isTouch,
        isLandscape,
        width,
        height,
      }}
    >
      {children}
    </ViewportContext.Provider>
  )
}

export const useViewport = () => {
  const viewport = useContext(ViewportContext)
  return viewport
}

export default useViewport
