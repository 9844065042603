export const pageview = (title: string, location?: string, url?: string): void => {
  if (process.env.APP_ENV === 'production') {
    window.gtag('config', process.env.GA_TRACKING_ID, {
      page_title: title,
      page_location: location,
      page_path: url,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action: string, label?: string, value?: number): void => {
  if (process.env.APP_ENV === 'production') {
    window.gtag('event', action, {
      event_category: 'Team Games',
      event_label: label,
      ...(value ? { value } : null),
    })
  }
}
