import { createSlice } from '@reduxjs/toolkit'
import _get from 'lodash/get'
import _uniq from 'lodash/uniq'

export const eventLobbySlice = createSlice({
  name: 'eventLobby',
  initialState: {
    eventInfo: null,
    groupInfo: null,
    gameInfo: [],
    userInfo: {},
    lobbyActivity: [],
    groupActivity: [],
    lobbyLeaderboard: [],
    roundLeaderboards: {},
    groupLeaderboard: [],
    isGamePlayBlocked: false, // requires manual action by clicking "Join Game"
    isPracticing: false, // reset by OutingStarted event
    practiceGroupId: null,
  },
  reducers: {
    setLobbyData: (state, action) => {
      const { lobbyData, options } = action.payload
      const { eventInfo, groupInfo, gameInfo } = lobbyData

      // eventInfo
      const { lobbyActivity, lobbyLeaderboard, roundLeaderboards, ...event } = eventInfo
      state.eventInfo = event
      state.eventInfo.schedule = getScheduleWithStartTime(event)
      state.lobbyActivity = (lobbyActivity || []).reverse()
      state.lobbyLeaderboard = lobbyLeaderboard
      state.roundLeaderboards = convertRoundLeaderboards(roundLeaderboards)

      // groupInfo
      if (groupInfo) {
        const { activity, leaderboard, ...group } = groupInfo
        state.groupInfo = group
        state.groupActivity = (activity || []).reverse()
        state.groupLeaderboard = leaderboard
      }

      // gameInfo
      state.gameInfo = gameInfo

      state.isPracticing = false
      if (options.join) {
        state.isGamePlayBlocked = groupInfo !== null
      }
    },
    // updateEventDetails: (state, action) => {
    //   const { title, secondsUntilStart } = action.payload
    //   if (state.eventInfo && state.eventInfo.schedule) {
    //     state.eventInfo.title = title
    //     state.eventInfo.schedule = getScheduleWithStartTime(state.eventInfo, secondsUntilStart)
    //   }
    // },
    setUserInfo: (state, action) => {
      for (const userId in action.payload) {
        state.userInfo[userId] = action.payload[userId]
      }
    },
    setGamePlayBlocked: (state, action) => {
      state.isGamePlayBlocked = action.payload
    },
    setActiveParticipants: (state, action) => {
      state.eventInfo = {
        ...state.eventInfo,
        activeParticipants: action.payload,
      }
    },
    appendLobbyActivity: (state, action) => {
      state.lobbyActivity = [action.payload, ...state.lobbyActivity]
    },
    appendGroupActivity: (state, action) => {
      state.groupActivity = [action.payload, ...state.groupActivity]
    },
    setLobbyLeaderboard: (state, action) => {
      state.lobbyLeaderboard = action.payload
    },
    setRoundLeaderboard: (state, action) => {
      const { roundNumber, leaderboard } = action.payload
      state.roundLeaderboards[roundNumber] = convertLeaderboard(leaderboard)
    },
    setGroupLeaderboard: (state, action) => {
      state.groupLeaderboard = action.payload
    },
    setPracticeMode: (state, action) => {
      const { isPracticing, practiceGroupId } = action.payload
      state.isPracticing = isPracticing
      state.practiceGroupId = practiceGroupId
    },
  },
})

export const { actions } = eventLobbySlice

export const selectors = {
  eventInfo: state => _get(state, 'eventLobby.eventInfo', {}),
  eventStartTime: state => _get(state, 'eventLobby.eventInfo.schedule.startTime'),
  groupCount: state => _get(state, 'eventLobby.eventInfo.groupCount'),
  eventId: state => _get(state, 'eventLobby.eventInfo.id'),
  eventPasscode: state => _get(state, 'eventLobby.eventInfo.passcode'),
  canEditEvent: state => _get(state, 'eventLobby.eventInfo.canEdit'),
  eventTitle: state => _get(state, 'eventLobby.eventInfo.title'),
  eventStatus: state => _get(state, 'eventLobby.eventInfo.status'),
  eventStatusBeforePause: state => _get(state, 'eventLobby.eventInfo.pauseData.eventStatus'),
  eventCreatedBy: state => _get(state, 'eventLobby.eventInfo.createdBy'),
  eventVideoCallLink: state => _get(state, 'eventLobby.eventInfo.videoCallLink'),
  allRounds: state => _get(state, 'eventLobby.eventInfo.schedule.rounds'),
  roundNumber: state => _get(state, 'eventLobby.eventInfo.round.number'),
  roundStatus: state => _get(state, 'eventLobby.eventInfo.round.status'),
  numberOfRounds: state => _get(state, 'eventLobby.eventInfo.schedule.rounds', []).length,
  maxRoundDuration: state => {
    // in minutes
    const rounds = _get(state, 'eventLobby.eventInfo.schedule.rounds', [])
    const durations = rounds.map(e => e.roundSeconds / 60)
    return Math.max(...durations)
  },
  currentRound: state => {
    const rounds = _get(state, 'eventLobby.eventInfo.schedule.rounds', [])
    const roundNumber = _get(state, 'eventLobby.eventInfo.round.number')
    if (roundNumber) {
      return rounds[roundNumber - 1]
    }
    return null
  },
  currentGameSlug: state => {
    const rounds = _get(state, 'eventLobby.eventInfo.schedule.rounds', [])
    const roundNumber = _get(state, 'eventLobby.eventInfo.round.number')
    const roundIndex = roundNumber > 0 ? roundNumber - 1 : 0
    return _get(rounds, `${roundIndex}.gameSlug`, '')
  },
  upcomingSessionTime: state => {
    const rounds = _get(state, 'eventLobby.eventInfo.schedule.rounds', [])
    const roundNumber = _get(state, 'eventLobby.eventInfo.round.number')
    return _get(rounds, `${roundNumber - 1 || 0}.roundSeconds`, 0)
  },
  activeParticipants: state => _get(state, 'eventLobby.eventInfo.activeParticipants', []),
  playersCount: state => _get(state, 'eventLobby.eventInfo.activeParticipants', []).length, // of event not group

  groupInfo: state => _get(state, 'eventLobby.groupInfo', {}),
  groupInfoId: state => _get(state, 'eventLobby.groupInfo.id', ''),
  groupInfoToken: state => _get(state, 'eventLobby.groupInfo.token'),
  groupName: state => _get(state, 'eventLobby.groupInfo.name'),
  groupParticipants: state => _get(state, 'eventLobby.groupInfo.participants', []),
  gameSlugs: state => {
    const rounds = _get(state, 'eventLobby.eventInfo.schedule.rounds', [])
    const gameSlugSet = new Set(rounds.map(round => round.gameSlug))
    return Array.from(gameSlugSet)
  },
  gameInfo: state => _get(state, 'eventLobby.gameInfo', {}),
  userInfo: state => _get(state, 'eventLobby.userInfo', {}),
  lobbyActivity: state => _get(state, 'eventLobby.lobbyActivity', []),
  groupActivity: state => _get(state, 'eventLobby.groupActivity', []),
  lobbyLeaderboard: state => _get(state, 'eventLobby.lobbyLeaderboard', []),
  roundLeaderboards: state => _get(state, 'eventLobby.roundLeaderboards', {}),
  groupLeaderboard: state => _get(state, 'eventLobby.groupLeaderboard', []),
  isGamePlayBlocked: state => _get(state, 'eventLobby.isGamePlayBlocked'),
  isPracticing: state => _get(state, 'eventLobby.isPracticing'),
  practiceGroupId: state => _get(state, 'eventLobby.practiceGroupId'),
  isBotGroup: state => _get(state, 'eventLobby.groupInfo.isBotGroup'),
}

export default eventLobbySlice.reducer

// based on "secondsUntilStart", calculate start time of each round
function getScheduleWithStartTime(event, timeLeft) {
  const secondsUntilOutingStart = timeLeft || _get(event, 'schedule.secondsUntilStart')
  const allRounds = _get(event, 'schedule.rounds', [])
  const currentRound = _get(event, 'round')
  const nowInSeconds = Math.floor(new Date().valueOf() / 1000)
  const outingStartTime = nowInSeconds + secondsUntilOutingStart

  var newRounds = []
  let margin = 0
  let index = 0

  for (const round of allRounds) {
    if (currentRound && index === currentRound.number - 1) {
      margin = currentRound.secondsUntilStart // reset margin for upcoming rounds, don't care previous rounds
    } else {
      margin += round['beforeRoundSeconds']
    }
    newRounds.push({
      ...round,
      startTime: (currentRound ? nowInSeconds : outingStartTime) + margin,
    })
    margin += round['roundSeconds']
    index++
  }

  return {
    ...event.schedule,
    startTime: outingStartTime,
    rounds: newRounds,
  }
}

// convert array-format leaderboard to key-value-format
// @TODO: apply to lobby and group leaderboards
function convertLeaderboard(scoreList) {
  try {
    return scoreList.reduce((result, item) => {
      result[item.id] = item
      return result
    }, {})
  } catch {
    return {}
  }
}
function convertRoundLeaderboards(roundScoreMap) {
  const newRoundScoreMap = {}
  try {
    for (const [round, scoreList] of Object.entries(roundScoreMap)) {
      newRoundScoreMap[round] = convertLeaderboard(scoreList)
    }
  } catch {}
  return newRoundScoreMap
}
