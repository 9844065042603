import { createSlice } from '@reduxjs/toolkit'
import _get from 'lodash/get'

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    appConfig: {},
    currentScreen: '',
    gameMap: {},
  },
  reducers: {
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload
    },
    setConfig: (state, action) => {
      state.appConfig = action.payload
    },
    setGames: (state, action) => {
      action.payload.forEach(game => {
        state.gameMap[game.game_slug] = game
      })
    },
  },
})

export const { actions } = globalSlice

export const selectors = {
  appConfig: state => _get(state, 'global.appConfig'),
  appUrl: state => _get(state, 'global.appConfig.appUrl'),
  gameMap: state => _get(state, 'global.gameMap'),
  currentScreen: state => _get(state, 'global.currentScreen'),
}

export default globalSlice.reducer
