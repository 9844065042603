import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import global from 'redux/global_slice'
import eventLobby from 'redux/event_lobby_slice'

export function makeStore() {
  return configureStore({
    reducer: {
      global,
      eventLobby,
    },
  })
}

const store = makeStore()

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>

export default store
