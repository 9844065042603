import 'bootstrap/dist/css/bootstrap.css'
import 'styles/react_app.scss'
import 'styles/globals.css'
import 'styles/base_modal.scss'
import 'styles/header.scss'
import 'styles/footer.scss'
import 'styles/dropdown.scss'
import 'styles/slick.scss'
import 'styles/landing.scss'
import 'styles/page_event_lobby.scss'
import 'styles/screen_main_lobby.scss'
import 'styles/screen_round_play.scss'
import 'styles/screen_event_finished.scss'
import 'styles/activity_log.scss'
import 'styles/event_info_block.scss'
import 'styles/game_info_block.scss'
import 'styles/game_session_iframe.scss'
import 'styles/group_leaderboard.scss'
import 'styles/lobby_banner_finished.scss'
import 'styles/lobby_banner_main.scss'
import 'styles/lobby_leaderboard.scss'
import 'styles/medal_table_block.scss'
import 'styles/mobile_game_topbar.scss'
import 'styles/outing_manual_control.scss'
import 'styles/wp_listing.scss'

import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'
import { AppWrapper } from 'contexts/app_context'
import { ViewportProvider } from 'contexts/viewport'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import * as gtag from 'lib/gtag'

import store from 'app/store'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [queryClient] = useState(() => new QueryClient())

  useEffect(() => {
    const handleRouteChange = () => {
      const pagePathName = window.location.pathname
      const pageUrl = window.location.href
      const switcher = {
        '/': 'Home',
        '/landing': 'Landing',
        '/contact-us': 'Contact',
      }
      let pageTitle = switcher[pagePathName]
      if (pagePathName.startsWith('/events/')) {
        pageTitle = 'Outing Lobby'
      }
      if (pageTitle) {
        gtag.pageview(pageTitle, pageUrl, pagePathName)
      }
    }
    handleRouteChange() // initial app render
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Provider store={store}>
      <ViewportProvider>
        <AppWrapper>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <Component {...pageProps} />
            </Hydrate>
          </QueryClientProvider>
        </AppWrapper>
      </ViewportProvider>
    </Provider>
  )
}

export default MyApp
