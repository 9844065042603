import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { EventStatus } from 'utils/constants'
import axios from 'axios'
import Cookies from 'js-cookie'

const initialState = {
  state: {
    configError: null,
    configLoading: true,
    appConfig: null,
    clientScreen: '',
    gameData: null,
    gameSelected: [],
    curGameSlug: [],
    eventJustCreated: null,
    upcomingEvents: null,
    showModalMsg: false,
    messageAlert: '',
    showFaqModal: false,
    showModalConfirm: false,
    messageConfirm: '',
    actionConfirm: null,
    actionCancel: null,
    hideGameDetail: false,
    showModalJoinOuting: false,
    passcodeModalJoinOuting: '',
    showModalReceiveOutingDetails: false,
  },
  dispatch: null,
}

// eslint-disable-next-line complexity
const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CONFIG_LOADING':
      return {
        ...state,
        configLoading: action.payload,
      }
    case 'SET_CONFIG_ERROR':
      return {
        ...state,
        configError: action.payload,
      }
    case 'SET_APP_CONFIG':
      return {
        ...state,
        appConfig: action.payload,
      }
    case 'SET_GAME_DATA':
      return {
        ...state,
        gameData: [
          ...action.payload.filter(game => !!game.is_enabled),
          ...action.payload.filter(game => !game.is_enabled),
        ],
      }
    case 'SET_CUR_GAME':
      return {
        ...state,
        curGameSlug: action.payload,
      }
    case 'SET_GAME_SELECTED':
      return {
        ...state,
        gameSelected: action.payload,
      }
    case 'ADD_GAME_SELECTED':
      return {
        ...state,
        gameSelected: [...state.gameSelected, action.payload],
      }
    case 'REMOVE_GAME_SELECTED':
      return {
        ...state,
        gameSelected: state.gameSelected.filter(id => id !== action.payload),
      }
    case 'SET_CLIENT_SCREEN':
      return {
        ...state,
        clientScreen: action.payload,
      }
    case 'SET_EVENT_JUST_CREATED':
      return {
        ...state,
        eventJustCreated: action.payload,
      }
    case 'SET_UPCOMING_EVENT':
      return {
        ...state,
        upcomingEvents: action.payload.filter(event => {
          if (event.status === EventStatus.Complete) {
            return false
          }
          const { start_time: start, duration_seconds: duration } = event
          const date = new Date()
          const seconds = date.getTime() / 1000
          return seconds < start + duration
        }),
      }
    case 'SHOW_MODAL_MESSAGE':
      return {
        ...state,
        showModalMsg: action.payload,
      }
    case 'SET_ALERT_MESSAGE':
      return {
        ...state,
        messageAlert: action.payload,
      }
    case 'SHOW_FAQ_MODAL':
      return {
        ...state,
        showFaqModal: action.payload,
      }
    case 'SHOW_MODAL_CONFIRM':
      return {
        ...state,
        showModalConfirm: action.payload,
      }
    case 'SET_CONFIRM_MESSAGE':
      return {
        ...state,
        messageConfirm: action.payload,
      }
    case 'SET_CONFIRM_ACTION':
      return {
        ...state,
        actionConfirm: action.payload,
      }
    case 'SET_CANCEL_ACTION':
      return {
        ...state,
        actionCancel: action.payload,
      }
    case 'HIDE_GAME_DETAIL':
      return {
        ...state,
        hideGameDetail: action.payload,
      }
    case 'SHOW_MODAL_JOIN_OUTING':
      return {
        ...state,
        showModalJoinOuting: action.payload,
      }
    case 'SET_MODAL_JOIN_OUTING_PASSCODE':
      return {
        ...state,
        passcodeModalJoinOuting: action.payload,
      }
    case 'SHOW_MODAL_RECEIVE_OUTING_DETAILS':
      return {
        ...state,
        showModalReceiveOutingDetails: action.payload,
      }
    default:
      return state
  }
}

const AppContext = createContext(initialState)

export const AppWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState.state)
  const oldJwtToken = Cookies.get('jwtToken')
  const displayName = Cookies.get('displayName')

  useEffect(() => {
    async function getGuestToken() {
      try {
        var response
        if (!oldJwtToken) {
          response = await axios.get(`${process.env.API_HOST}/api/guest/token`)
        } else {
          response = await axios.get(`${process.env.API_HOST}/api/guest/token`, {
            headers: {
              Authorization: `Bearer ${oldJwtToken}`,
            },
          })
        }
        var inSixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000)
        Cookies.set('jwtToken', response.data.jwt, {
          expires: inSixtyMinutes,
        })
        Cookies.set('userToken', response.data.userToken, {
          expires: inSixtyMinutes,
        })
        if (displayName)
          Cookies.set('displayName', displayName, {
            expires: inSixtyMinutes,
          })
      } catch (error) {
        console.log(error)
      }
    }
    getGuestToken()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
}

export function useAppContext() {
  const { state, dispatch } = useContext(AppContext)
  return [state, dispatch]
}
